import Excel from 'exceljs'
import { saveAs } from 'file-saver'

const generateAndDownloadExcelFile = async (
	fileName,
	columns,
	data,
	successCallback,
	errorCallback,
	finallyCallback,
) => {
	const workSheetName = 'Sheet-1'
	const workbook = new Excel.Workbook()
	try {
		const worksheet = workbook.addWorksheet(workSheetName)

		// Add column headers (adjust as needed)
		worksheet.columns = columns?.filter((e) => e?.accessor)?.map((e) => ({ header: e.header, key: e.accessor }))

		// Add rows to the worksheet
		data.forEach((item) => {
			worksheet.addRow(item)
		})

		// Generate the Excel file and save it
		const buffer = await workbook.xlsx.writeBuffer()
		const blob = new Blob([buffer], { type: 'application/octet-stream' })
		saveAs(blob, `${fileName}.xlsx`)

		if (successCallback) successCallback()
	} catch (error) {
		if (errorCallback) errorCallback(error?.message || 'No se pudo exportar el archivo.')
	} finally {
		workbook.removeWorksheet(workSheetName)
		if (finallyCallback) {
			finallyCallback()
		}
	}
}

export default generateAndDownloadExcelFile
