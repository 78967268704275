import xirr from 'xirr'
import { format, differenceInCalendarMonths, getDaysInMonth, differenceInDays, compareAsc } from 'date-fns'
import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'

export const calculateTotalDuration = (finishDate, startDate) => {
	const initDate = new Date(format(startDate, 'yyyy-MM-dd 00:00:00'))
	const finDate = new Date(format(finishDate, 'yyyy-MM-dd 00:00:00'))

	const difference = differenceInCalendarMonths(finDate, initDate)
	if (difference >= 0 && difference < 1) {
		const daysInMonth = getDaysInMonth(startDate)
		const differenceOfDays = differenceInDays(finDate, initDate)
		const datePercentage = differenceOfDays / daysInMonth

		return datePercentage.toFixed(2)
	}

	return difference
}

export function fv(rate, nper, _, investment) {
	rate = parseFloat(rate)

	if (!nper) {
		return 0
	}
	nper = nper - 1

	let fv_value

	const x = Math.pow(1 + rate, (1 / 365) * nper)
	fv_value = -investment * x

	return parseFloat(fv_value.toFixed(2))
}

export const calculateToPay = (retention, benefit, investment, liquidation) => {
	const profitWithholding = (retention.replace('%', '') / 100) * benefit
	const total =
		liquidation === 'benefits-only'
			? benefit - profitWithholding
			: benefit - profitWithholding + replaceStringToNum(investment)

	return benefit ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(total) : null
}

export const changeIncomeReciveDate = (incomeRecivedDate, startDateUpdated) => {
	let date
	if (incomeRecivedDate) {
		if (compareAsc(new Date(incomeRecivedDate), new Date(startDateUpdated)) === -1) {
			date = startDateUpdated
		} else {
			date = incomeRecivedDate
		}
		return date
	}
}

export const calculateToTIR = (incomeRecivedDate, depositDate, benefit, investment) => {
	const benefitCalc = typeof benefit === 'number' ? benefit : replaceStringToNum(benefit)
	const TIR = xirr([
		{ amount: -replaceStringToNum(investment), when: new Date(incomeRecivedDate) },
		{ amount: benefitCalc, when: new Date(depositDate) },
	])

	return benefit ? Number((TIR * 100).toFixed(2)) : null
}

export const handleBenefit12 = (dates, amount) => {
	let data = fv(0.12, dates, 0, -amount)
	return data
}

export const zeroFill = (number, width) => {
	if (number) {
		width -= number.toString().length
		if (width > 0) {
			return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number
		}
		return number + ''
	}
}

export const returValue = (value) => {
	return value ? value : ''
}

export const ibanValidator = (iban) => {
	return !iban || iban.toString().length !== 24 || iban.slice(0, 2).toLowerCase() !== 'es'
}
export const percentageSum = (investments, cost) => {
	let notInvestmentsDeleted = investments.filter((item) => !item.isDeleted)
	let invest = notInvestmentsDeleted.map((item) =>
		parseFloat((replaceStringToNum(item.amount) / replaceStringToNum(cost)) * 100),
	)
	return `${invest.reduce((previousValue, currentValue) => previousValue + currentValue, 0).toFixed(2)} %`
}

export const calculateSum = (values) => {
	let result = 0
	values.map((value) => value !== '' && (result = parseFloat(result) + parseFloat(value)))
	return result !== 0 ? result.toFixed(2) : ''
}
export const matchDocumentName = (documentName) => {
	switch (documentName) {
		case 'Frente DNI':
			return 'profileDniFront'
		case 'Dorso DNI':
			return 'profileDniBack'
		case 'Contrato KyC':
			return 'kycContract'

		default:
			break
	}
}

export const findDocument = (infoModalData, documentName) => {
	if (infoModalData.documents) {
		const doc = infoModalData.documents.filter((item) => item.documentName === documentName)

		return doc.length > 0 ? doc[0].documentPath : ''
	}
}

export const findDocumentUrl = (userInfo, documentName) => {
	if (userInfo[documentName]) {
		return userInfo[documentName]
	}
}

export const findDocumentText = (userInfo, documentName) => {
	if (!userInfo.documentsFiles || userInfo.documentsFiles.length < 1) {
		return ''
	} else {
		const field = userInfo.documentsFiles.find((item) => item.name === documentName)
		return field ? field.url : ''
	}
}

export const simplifyTernary = (condition, firstOption, secondOption) => (condition ? firstOption : secondOption)
